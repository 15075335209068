import React, { useEffect, useState } from 'react';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencil, faTrash, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Audio } from 'react-loader-spinner';
import axios from 'axios';
import './subject.css'
import TopNavWithBack from '../../components/TopNav/topnavwithback';
import { baseurl } from '../../urls';
import { message } from 'antd';
function SingleSubject() {
    var base = sessionStorage.getItem('base')
    var bucket = sessionStorage.getItem('bucket');
    var title = sessionStorage.getItem('title');
    const { name } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    var sid = searchParams.get('id');
    var [isLoaded, setIsLoaded] = useState(false);
    var [chapterList, setChapterList] = useState([]);
    const [subjectType, setSubjectType] = useState('free');
    const [selappIcon, setSelAppIcon] = useState();
    const [appPreview, setAppPreview] = useState("");
    const [selSubject, setSelSubject] = useState({
        id: 'id',
        title: '',
        isPremium: false,
        description: '',
        image: '',
        imageurl: ''
    });
    function getChapters() {
        axios.get(baseurl + "getchapters?base=" + base + "&bucket=" + bucket + "&id=" + searchParams.get('id'))
            .then(res => res.data)
            .then((res) => {
                setIsLoaded(true);
                setChapterList(res);
            });
    }
    function AddSubject() {
        var name = document.getElementById('sub_title').value;
        var desc = document.getElementById('sub_desc').value;
        var formdata = new FormData();
        if (!appPreview) {
            message.error("Poster Cannot be empty!");
            return;
        }
        if (!name || !desc) {
            message.error("Title and description are mandatory to add a Chapter!");
            return;
        }

        formdata.append('poster', selappIcon);
        formdata.append('posterurl', appPreview);
        formdata.append('name', name);
        formdata.append('description', desc);
        formdata.append('isPremium', subjectType);
        formdata.append('id', selSubject.id);
        axios.post(baseurl + "addchapter?base=" + base + "&bucket=" + bucket + '&sid=' + sid, formdata).then(() => {
            message.success('Chapter added successfully!!')
            window.location.reload();
        })
    }
    function SelectPoster(e) {
        var file = e.target.files[0];
        setSelAppIcon(file);
        var imageurl = URL.createObjectURL(file);
        setAppPreview(imageurl);
    }
    function AddModal() {
        setSelSubject({
            id: '',
            title: '',
            isPremium: false,
            description: '',
            image: '',
            imageurl: ''
        });
        setSelAppIcon('');
        setAppPreview('');
        var overlay = document.getElementById('subjectmodal');
        overlay.classList.toggle('hide');
        var modal = document.getElementById('modal');
        modal.classList.toggle('hide');
    }
    function selectChapter(e) {
        var isP = e.currentTarget.dataset.ispremium;
        var t = e.currentTarget.dataset.title;
        var d = e.currentTarget.dataset.desc;
        var p = e.currentTarget.dataset.poster;
        var id = e.currentTarget.dataset.id;
        var chap = {
            id: id,
            title: t,
            description: d,
            isPremium: (isP == 'true'),
            image: '',
            imageurl: p
        }
        setSelSubject(chap);
        setAppPreview(p);
        setSubjectType(chap.isPremium);
        var overlay = document.getElementById('subjectmodal');
        overlay.classList.toggle('hide');
        var modal = document.getElementById('modal');
        modal.classList.toggle('hide');

    }
    function deleteChapter(e) {
        var cid = e.currentTarget.dataset.id;
        if (window.confirm('Are you sure you want to delete this Chapter?')) {
            axios.post(baseurl + 'deleteChapter?base=' + base + '&cid=' + cid + '&sid=' + sid)
                .then(() => {
                    window.location.reload();
                })
        } else {
            return;
        }

    }
    function CloseModal() {
        var overlay = document.getElementById('subjectmodal');
        var modal = document.getElementById('modal');
        overlay.classList.toggle('hide');
        modal.classList.toggle('hide');
    }

    useEffect(() => {
        getChapters();
    }, [])

    return (<>
        {name ? (<div className='main_page' style={{ height: "100vh" }}>
            <TopNavWithBack title={title} />
            <div id="pageContent" className='mx-5 p-3 single_page_contents'>

                <div className='page_title'>
                    <h1>Chapters</h1>
                    <p className='subtext'>Manage Chapters of {name}</p>
                    <button onClick={() => AddModal()} className='btn btn-back-1'> + Add Chapter</button>
                    <hr />
                </div>
                <div id="subjectmodal" className='overlay_dark hide'>
                    <div id="modal" className='left-modal hide'>
                        <div className='modal_header'>
                            {selSubject.title != '' && <h4>Edit Chapter</h4>}
                            {selSubject.title == '' && <h4>Add Chapter</h4>}
                            <div className='modal_close' onClick={() => CloseModal()}>
                                <FontAwesomeIcon icon={faTimes} />
                            </div>
                        </div>
                        <div className='modal-body row'>
                            <div className='subjectposter'>
                                {appPreview && <img src={appPreview} />}
                                {!appPreview && <label className='subPosterlabel'>+Add Chapter Poster <input type='file' onChange={(e) => SelectPoster(e)} hidden /></label>}
                            </div>
                            <div className='col-md-7'>
                                <div className='input_group'>
                                    <input id='sub_title' className='form-control' type='text' name='subjectTitle' placeholder='Subject title' defaultValue={selSubject.title} />
                                </div>
                                <div className='radio_set mb-3'>
                                    <input checked={!subjectType} className='inp_radio' type='radio' name='subjectType' value={'Free'} onChange={() => setSubjectType(false)} /> Free
                                    <input checked={subjectType} className='inp_radio' type='radio' name='subjectType' value={'Premium'} onChange={() => setSubjectType(true)} /> Premium
                                </div>
                                <div className='input_group'>
                                    <textarea id='sub_desc' defaultValue={selSubject.description} rows={4} className='form-control' name='description' placeholder='Description'></textarea>
                                </div>
                                {selSubject.title != '' && <button className='form-control mt-3 submit_btn' onClick={() => AddSubject()}>Edit Chapter</button>}
                                {selSubject.title == '' && <button className='form-control mt-3 submit_btn' onClick={() => AddSubject()}>Add Chapter</button>}
                            </div>



                        </div>


                    </div>

                </div>

                <div className='head'>
                    {!isLoaded &&
                        <div className='spinner_container'>
                            <Audio height="80"
                                width="80"
                                radius="9"
                                color="green"
                                ariaLabel="loading"
                                wrapperStyle
                                wrapperClass />
                        </div>}
                    {isLoaded && chapterList.map((sub) => {
                        var suburl1 = sub.name.replace(/\:/g, '');
                        var suburl2 = suburl1.replace(/\//g, '');
                        var suburl = suburl2.replace(/\s/g, '');

                        return (<>

                            <div className='subject_card' style={{ backgroundImage: "url(" + sub.poster + ")" }}>
                                <Link to={`${suburl}?id=${searchParams.get('id')}&cid=${sub.id}`}>
                                    <div className='subject_title'>
                                        <h4>{sub.name}</h4>
                                        <p className='subject_counts'>{sub.file.pageCount > 0 ? 1 : 0} Notes, {sub.flashCardCount} Cards, {sub.quizCount} Quiz </p>
                                    </div>
                                </Link>
                                <div className='options'>
                                    <p className='option add_pointer' data-title={sub.name} data-desc={sub.description} data-ispremium={sub.isPremium} data-poster={sub.poster} data-id={sub.id} onClick={(e) => selectChapter(e)}>
                                        <FontAwesomeIcon icon={faPencil} />
                                        Edit
                                    </p>
                                    <p className='option add_pointer' data-id={sub.id} onClick={(e) => deleteChapter(e)}>
                                        <FontAwesomeIcon icon={faTrash} />
                                        Delete
                                    </p>
                                </div>
                            </div>

                        </>)
                    })}

                </div>
            </div>
        </div>) : (
            <div lassName='main_page' style={{ height: "100vh" }}>
                <h1> No Subject</h1>
            </div>)
        }
    </>);

}
export default SingleSubject;