import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { message } from "antd";
import axios from "axios";
import React, { useState } from "react";
import { baseurl } from "../urls";
import { Document, Page } from "react-pdf";

const AddNoteModal = (props) => {
    var base = sessionStorage.getItem('base')
    var bucket = sessionStorage.getItem('bucket')
    const [preview, setPreview] = useState(props.note.imageUrl);
    const [image, setImage] = useState();
    const [size, setSize] = useState();
    const [pages, setPages] = useState();
    const SelectPoster = (e) => {
        var file = e.target.files[0];
        setImage(file);
        var imageurl = URL.createObjectURL(file);
        setPreview(imageurl);
        setSize(file.size);
    }
    const CloseModal = () => {
        props.togglemodal(false);
    }
    const onDocumentLoadSuccess = ({ numPages }) => {
        setPages(numPages);
    }
    const submitNote = () => {

        var formdata = new FormData();

        if (!preview) {
            message.error("Poster is mandatory to add a Note!");
            return;
        }
        formdata.append('cid', props.cid);
        formdata.append('sid', props.sid);
        formdata.append('image', image);
        formdata.append('imageUrl', preview);
        formdata.append('pages', pages);
        formdata.append('size', size);
        formdata.append('id', props.note.id);
        axios.post(baseurl + "addnote?base=" + base + "&bucket=" + bucket, formdata).then(() => {
            message.success('Note added successfully!!')
            window.location.reload();
        })
    }

    return <>
        <div id="subjectmodal" className='overlay_dark'>
            <div id="modal" className='left-modal'>
                <div className='modal_header'>
                    {!props.note.id && <h4>Add Note</h4>}
                    {props.note.id && <h4>Edit Note</h4>}
                    <div className='modal_close' onClick={() => CloseModal()}>
                        <FontAwesomeIcon icon={faTimes} />
                    </div>
                </div>
                <div className='modal-body'>
                    {preview && <p class="center-align"> Click on Image to replace</p>}
                    <div className='subjectposter'>
                        {preview &&
                            <>
                                <p>PageCount:{pages}</p>
                                <p>Size:{size}</p>
                                <Document file={preview} onLoadSuccess={onDocumentLoadSuccess}><Page pageNumber={1} /></Document>

                            </>}
                        {!preview && <label className='subPosterlabel'>+Upload Note PDF <input type='file' accept="application/pdf" onChange={(e) => SelectPoster(e)} hidden /></label>}
                    </div>
                    <div className="col-md-5 mx-auto">
                        {props.note.id && <button className="form-control mt-3 submit_btn" onClick={() => submitNote()}>Edit Note</button>}
                        {!props.note.id && <button className="form-control mt-3 submit_btn" onClick={() => submitNote()}>Add Note</button>}
                    </div>



                </div>


            </div>

        </div>
    </>
}
export default AddNoteModal;