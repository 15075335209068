import React from 'react'
import './topnav.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPowerOff } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom';

function AdminNav(props) {
    let navigate = useNavigate();
    function logout() {
        sessionStorage.clear();
        navigate('/login');
    }
    return (
        <>
            <div className='container_fluid top_nav'>
                <div className='brandback'>
                    <h4 style={{ fontWeight: '300' }}>Welcome <span style={{ fontWeight: 'bold' }}>{props.username}</span></h4>
                    <span class="version_number">v 2.2</span>
                    <a className='btn btn-light logout_btn' onClick={() => logout()}><FontAwesomeIcon icon={faPowerOff} /> Logout</a>
                </div>

            </div>
        </>
    );
}
export default AdminNav;