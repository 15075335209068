import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { message } from "antd";
import axios from "axios";
import React, { useState } from "react";
import { baseurl } from "../urls";

const AddQuizModal = (props) => {
    var base = sessionStorage.getItem('base')
    var bucket = sessionStorage.getItem('bucket')
    const [questionType, setQuestionType] = useState(props.isPremium);

    const CloseModal = () => {
        props.togglemodal(false);
    }
    const submitQuiz = () => {
        var question = document.getElementById('sub_question').value;
        var answer = document.getElementById('sub_answer').value;
        var exp = document.getElementById('sub_exp').value;
        var op1 = document.getElementById('sub_option1').value;
        var op2 = document.getElementById('sub_option2').value;
        var op3 = document.getElementById('sub_option3').value;
        var op4 = document.getElementById('sub_option4').value;
        var formdata = new FormData();

        if (!question || !answer || !exp || !op1 || !op2 || !op3 || !op4) {
            message.error("All the fields are mandatory to add a Quiz!");
            return;
        }
        formdata.append('cid', props.cid);
        formdata.append('sid', props.sid);
        formdata.append('question', question);
        formdata.append('correctAns', answer);
        formdata.append('description', exp);
        formdata.append('option1', op1);
        formdata.append('option2', op2);
        formdata.append('option3', op3);
        formdata.append('option4', op4);
        formdata.append('quizType', questionType);
        formdata.append('id', props.quiz.id);
        axios.post(baseurl + "addquiz?base=" + base + "&bucket=" + bucket, formdata).then(() => {
            message.success('Quiz added successfully!!')
            window.location.reload();
        })
    }

    const deleteQuiz = (e) => {
        var id = e.currentTarget.dataset.id;
        message.warning('Deleting Quiz temporarily suspended!')
        // axios.post(baseurl + 'deletequiz?base=' + props.base + '&bucket=' + props.bucket + '&sid=' + props.sid + '&cid=' + props.cid + '&id=' + id)
        //     .then(() => {
        //         window.location.reload();
        //     })
    }
    return <>
        <div id="subjectmodal" className='overlay_dark'>
            <div id="modal" className='left-modal'>
                <div className='modal_header'>
                    <h4>Add Quiz</h4>
                    <div className='modal_close' onClick={() => CloseModal()}>
                        <FontAwesomeIcon icon={faTimes} />
                    </div>
                </div>
                <div className='modal-body row'>

                    <div className='container'>
                        <div className='input_group'>
                            <textarea rows={3} id='sub_question' className='form-control mb-3' type='text' name='question' placeholder='Question' defaultValue={props.quiz.question}></textarea>
                            <input id='sub_answer' className='form-control mb-3' type='text' name='answer' placeholder='Answer' defaultValue={props.quiz.answer} />
                        </div>
                        <div className='radio_set mb-3'>
                            <input checked={!questionType} className='inp_radio' type='radio' name='questionType' value={'Single'} onChange={() => setQuestionType(false)} /> Single
                            <input checked={questionType} className='inp_radio' type='radio' name='questionType' value={'Multiple'} onChange={() => setQuestionType(true)} /> Multiple
                        </div>
                        <div className="input_option_group row">
                            <div className="col-md-6 mb-3">
                                <input id='sub_option1' className='form-control' type='text' name='option1' placeholder='option1' defaultValue={props.quiz.option1} />
                            </div>
                            <div className="col-md-6 mb-3">
                                <input id='sub_option2' className='form-control' type='text' name='option2' placeholder='option2' defaultValue={props.quiz.option2} />
                            </div>
                            <div className="col-md-6 mb-3">
                                <input id='sub_option3' className='form-control' type='text' name='option3' placeholder='option3' defaultValue={props.quiz.option3} />
                            </div>
                            <div className="col-md-6 mb-3">
                                <input id='sub_option4' className='form-control' type='text' name='option4' placeholder='option4' defaultValue={props.quiz.option4} />
                            </div>
                        </div>
                        <div className='input_group mb-2'>
                            <textarea id='sub_exp' defaultValue={props.quiz.explanation} rows={4} className='form-control' name='explanation' placeholder='Explanation'></textarea>
                        </div>
                    </div>
                </div>
                <div className="col-md-5 mx-auto">
                    {props.quiz.id && <button className="form-control mt-3 submit_btn" onClick={() => submitQuiz()}>Edit Quiz</button>}
                    {!props.quiz.id && <button className="form-control mt-3 submit_btn" onClick={() => submitQuiz()}>Add Quiz</button>}
                </div>
            </div>
        </div>
    </>
}
export default AddQuizModal;