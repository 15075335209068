import React, { useEffect, useState } from 'react';
import './settings.css';
import axios from 'axios';
import { baseurl } from '../../urls';

function Settings(props) {
    var app = sessionStorage.getItem('app')
    const [imageType, setImageType] = useState('image')
    const [isCalled, setIsCalled] = useState(false);
    const [banner, setBanner] = useState();
    const [appIcon, setAppIcon] = useState();
    const [selappIcon, setSelAppIcon] = useState();
    const [appPreview, setAppPreview] = useState("");
    const [selbanner, setSelBanner] = useState();
    const [preview, setPreview] = useState("");
    const [aid, setAId] = useState("");
    const [bid, setBId] = useState("");
    const imageTypeChange = (val) => {
        setImageType(val);
    }
    function GetSettings() {
        setIsCalled(true);
        axios.get(baseurl + "getsettings?base=" + props.base + "&bucket=" + props.bucket)
            .then(res => res.data)
            .then((res) => {
                setBanner(res.banner);
                setAppIcon(res.appIcon);
                setAId(res.aid);
                setBId(res.bid);
            });
    }
    function SelectIcon(e) {
        var file = e.target.files[0];
        setSelAppIcon(file);
        var imageurl = URL.createObjectURL(file);
        setAppPreview(imageurl);
    }
    function SelectBanner(e) {
        var file = e.target.files[0];
        setSelBanner(file);
        var imageurl = URL.createObjectURL(file);
        setPreview(imageurl);
    }
    function UploadIcon() {
        var formdata = new FormData();
        formdata.append('icon', selappIcon);
        formdata.append('type', '0');
        formdata.append('aid', aid);
        axios.post(baseurl + "postappicon?base=" + props.base + "&bucket=" + props.bucket, formdata).then(() => {
            window.location.reload();
        })
    }
    function UploadBannerIcon() {
        var formdata = new FormData();
        formdata.append('icon', selbanner);
        formdata.append('type', '0');
        formdata.append('bid', bid);
        axios.post(baseurl + "postbannericon?base=" + props.base + "&bucket=" + props.bucket, formdata).then(() => {
            window.location.reload();
        })
    }
    useEffect(() => {
        GetSettings();
    }, [])
    return (
        <>
            <div className='page_title'>
                <h1>Settings</h1>
                <p className='subtext'>Manage App Settings</p>
            </div>
            <div className='page_title'>
                <div className='col-md-5 mx-2 float-left'>
                    <h1>Intro Image/Video</h1>
                    <div className='radio_set mb-5'>
                        <input className='inp_radio' type='radio' name='imageType' value={'Image'} onChange={() => imageTypeChange('Image')} /> Image
                        <input className='inp_radio' type='radio' name='imageType' value={'Video'} onChange={() => imageTypeChange('Video')} /> Video
                    </div>
                    <div className='image_container'>
                        {banner && <img src={banner.media} />}
                    </div>
                    {preview && <div className='image_container'>
                        <img src={preview} />
                    </div>}
                    <div className='buttons_container'>
                        <label className='media_btn'>Select Media <input type='file' onChange={(e) => SelectBanner(e)} hidden /></label>
                        <button className='media_btn' onClick={() => UploadBannerIcon()}>Upload Media</button>
                    </div>

                </div>
                <div className='col-md-5 mx-1 float-left'>
                    <h1 className='mb-5'>AppIcon</h1>

                    <div className='image_container'>
                        {appIcon && <img src={appIcon.media} />}
                    </div>
                    {appPreview && <div className='image_container'>
                        <img src={appPreview} />
                    </div>}
                    <div className='buttons_container'>

                        <label className='media_btn'>Select Icon <input type='file' onChange={(e) => SelectIcon(e)} hidden /></label>
                        <button className='media_btn' onClick={() => UploadIcon()}>Upload Icon</button>
                    </div>

                </div>

            </div>
        </>
    );
};

export default Settings;