import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { message } from "antd";
import axios from "axios";
import React, { useState } from "react";
import { baseurl } from "../urls";

const AddCardModal = (props) => {
    var base = sessionStorage.getItem('base')
    var bucket = sessionStorage.getItem('bucket')
    const [subjectType, setSubjectType] = useState(props.isPremium);
    const [preview, setPreview] = useState(props.card.frontImageUrl);
    const [image, setImage] = useState();
    const [previewback, setPreviewBack] = useState(props.card.backImageUrl);
    const [imageback, setImageBack] = useState();
    const SelectFront = (e) => {
        var file = e.target.files[0];
        setImage(file);
        var imageurl = URL.createObjectURL(file);
        setPreview(imageurl);
    }
    const SelectBack = (e) => {
        var file = e.target.files[0];
        setImageBack(file);
        var imageurl = URL.createObjectURL(file);
        setPreviewBack(imageurl);
    }
    const CloseModal = () => {
        props.togglemodal(false);
    }
    const submitCard = () => {
        var question = document.getElementById('sub_question').value;
        var answer = document.getElementById('sub_answer').value;
        var formdata = new FormData();        
        if ((!preview || !previewback)) {
            if(question.length<1 || answer.length<1){
                message.error("Images or Text mandatory to add a Flash Card!");
                return;
            }            
        }
        formdata.append('cid', props.cid);
        formdata.append('sid', props.sid);
        formdata.append('frontText', question);
        formdata.append('backText', answer);
        formdata.append('front', image);
        formdata.append('fronturl', preview);
        formdata.append('back', imageback);
        formdata.append('backurl', previewback);
        formdata.append('id', props.card.id);
        axios.post(baseurl + "addcard?base=" + base + "&bucket=" + bucket, formdata).then(() => {
            message.success('Flash Card added successfully!!')
            window.location.reload();
        })
    }
    const deleteCard = (e) => {
        var id = e.currentTarget.dataset.id;
        message.warning('Deleting Card temporarily suspended!')
        // axios.post(baseurl + 'deletecard?base=' + props.base + '&bucket=' + props.bucket + '&sid=' + props.sid + '&cid=' + props.cid + '&id=' + id)
        //     .then(() => {
        //         window.location.reload();
        //     })
    }
    return <>
        <div id="subjectmodal" className='overlay_dark'>
            <div id="modal" className='left-modal'>
                <div className='modal_header'>
                    {!props.card.id && <h4>Add Card</h4>}
                    {props.card.id && <h4>Edit Card</h4>}
                    <div className='modal_close' onClick={() => CloseModal()}>
                        <FontAwesomeIcon icon={faTimes} />
                    </div>
                </div>
                <div className='modal-body row'>
                    <div className='subjectposter'>
                        {preview && <img src={preview} />}
                        {!preview && <label className='subPosterlabel'>+Add Card Poster <input type='file' onChange={(e) => SelectFront(e)} hidden /></label>}
                    </div>
                    <div className='subjectposter'>
                        {previewback && <img src={previewback} />}
                        {!previewback && <label className='subPosterlabel'>+Add Card Poster <input type='file' onChange={(e) => SelectBack(e)} hidden /></label>}
                    </div>
                </div>
                <div className="container">
                    <div className='input_group'>                        
                        <textarea rows={3} id='sub_question' className='form-control' type='text' name='question' placeholder='Question' defaultValue={props.card.fronttext} />
                    </div>
                    <br/>
                    <div className='input_group'>                        
                        <input id='sub_answer' className='form-control mb-3' type='text' name='answer' placeholder='Answer' defaultValue={props.card.backtext} />
                    </div>

                </div>
                <div className="col-md-5 mx-auto">
                    {props.card.id && <button className="form-control mt-3 submit_btn" onClick={() => submitCard()}>Edit Flash Card</button>}
                    {!props.card.id && <button className="form-control mt-3 submit_btn" onClick={() => submitCard()}>Add Flash Card</button>}
                </div>


            </div>

        </div>
    </>
}
export default AddCardModal;