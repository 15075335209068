import React, { useEffect, useState } from 'react'
import './topnav.css'
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faHome } from '@fortawesome/free-solid-svg-icons';

function TopNav(props) {
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    function SigninCheck() {
        var s = sessionStorage.getItem('isLoggedin');
        if (s != null && s) {
            setUsername(sessionStorage.getItem('userName'))
        } else {
            navigate('/login');
        }
    }
    useEffect(SigninCheck, []);
    return (
        <>
            <div className='container_fluid top_nav'>
                <div className='brandback'>                    
                    <a class="add_pointer color_white" onClick={() => navigate('/appList')}><FontAwesomeIcon icon={faHome} /></a>
                    <span>&nbsp;&nbsp;</span>
                    <h4>{props.title} Admin</h4>
                    <span class="version_number">v 2.2</span>
                </div>

            </div>
        </>
    );
}
export default TopNav;