import React, { Suspense, lazy } from 'react';

import './users.css'
import { Audio } from 'react-loader-spinner';
const UserList = lazy(() => import('../../components/Users/UserList'));

function Users(props) {

    return (
        <>
            <div className='page_title'>
                <h1>Users</h1>
                <p className='subtext'>Manage App Users</p>
            </div>

            <Suspense fallback={<div className='container'>
                <div className='spinner_container'>
                    <Audio height="80"
                        width="80"
                        radius="9"
                        color="green"
                        ariaLabel="loading"
                        wrapperStyle
                        wrapperClass />
                </div>

            </div>}>
                <UserList base={props.base} bucket={props.bucket} />
            </Suspense>

        </>
    );
};


export default Users;