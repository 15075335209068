import React, { Suspense, lazy, useState } from 'react';
import './subject.css'
import { Audio } from 'react-loader-spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { message } from 'antd';
import { baseurl } from '../../urls';
const SubjectList = lazy(() => import('../../components/subjectList'));

function Subject(props) {
    const [showModal, setShowModal] = useState(false);
    const [subjectType, setSubjectType] = useState('free');
    const [selappIcon, setSelAppIcon] = useState();
    const [appPreview, setAppPreview] = useState("");
    const [selSubject, setSelSubject] = useState({
        id: 'id',
        title: '',
        isPremium: false,
        description: '',
        image: '',
        imageurl: '',
        indx: '',
    });
    const SelectSubject = (sub) => {
        setSelSubject(sub);
        setAppPreview(sub.imageurl);
        setSubjectType(sub.isPremium);
        document.getElementById('sub_indx').value = sub.indx;
        var overlay = document.getElementById('subjectmodal');
        overlay.classList.toggle('hide');
        var modal = document.getElementById('modal');
        modal.classList.toggle('hide');
    }

    function AddSubject() {
        var name = document.getElementById('sub_title').value;
        var desc = document.getElementById('sub_desc').value;
        var indx = document.getElementById('sub_indx').value;
        var existingImageUrl = document.getElementById('editSubPoster').getAttribute('src');
        var formdata = new FormData();
        if (!existingImageUrl && !selappIcon) {
            message.error("Poster Cannot be empty! ");
            return;
        }
        if (!name || !desc) {
            message.error("Title and description are mandatory to add a Subject!");
            return;
        }

        formdata.append('poster', selappIcon);
        formdata.append('posterurl', appPreview);
        formdata.append('name', name);
        formdata.append('description', desc);
        formdata.append('indx', indx);
        formdata.append('isPremium', subjectType);
        formdata.append('id', selSubject.id);
        axios.post(baseurl + "addsubject?base=" + props.base + "&bucket=" + props.bucket, formdata).then(() => {
            message.success('Subject added successfully!!')
            window.location.reload();
        })
    }
    function SelectPoster(e) {
        var file = e.target.files[0];
        setSelAppIcon(file);
        var imageurl = URL.createObjectURL(file);
        setAppPreview(imageurl);
    }
    function AddSubjectModal() {
        setSelSubject({
            id: '',
            title: '',
            isPremium: false,
            description: '',
            image: '',
            imageurl: '',
            indx:'',
        });
        setSelAppIcon('');
        setAppPreview('');
        document.getElementById('sub_indx').value = 0;
        var overlay = document.getElementById('subjectmodal');
        overlay.classList.toggle('hide');
        var modal = document.getElementById('modal');
        modal.classList.toggle('hide');
    }
    function CloseSubjectModal() {
        var overlay = document.getElementById('subjectmodal');
        var modal = document.getElementById('modal');
        overlay.classList.toggle('hide');
        modal.classList.toggle('hide');
    }
    return (
        <>
            <div className='page_title'>
                <h1>Subjects</h1>
                <p className='subtext'>Manage Subjects Data</p>
                <button onClick={() => AddSubjectModal()} className='btn btn-back-1'> + Add Subject</button>
            </div>
            <div id="subjectmodal" className='overlay_dark hide'>
                <div id="modal" className='left-modal hide'>
                    <div className='modal_header'>
                        {selSubject.title !== '' && <h4>Edit Subject</h4>}
                        {selSubject.title === '' && <h4>Add Subject</h4>}
                        <div className='modal_close' onClick={() => CloseSubjectModal()}>
                            <FontAwesomeIcon icon={faTimes} />
                        </div>
                    </div>
                    <div className='modal-body row'>
                        <div className='subjectposter'>
                            {appPreview && <img id="editSubPoster" src={appPreview} />}
                            {!appPreview && <label className='subPosterlabel'>+Add Subject Poster <input type='file' onChange={(e) => SelectPoster(e)} hidden /></label>}
                        </div>
                        <div className='col-md-7'>
                            <div className='input_group'>
                                <label><strong>Title</strong></label>
                                <input id='sub_title' className='form-control' type='text' name='subjectTitle' placeholder='Subject title' defaultValue={selSubject.title} />
                            </div>
                            <div className='radio_set mb-3'>
                                <label><strong>Subject Type</strong></label>
                                <input checked={!subjectType} className='inp_radio' type='radio' name='subjectType' value={'Free'} onChange={() => setSubjectType(false)} /> Free
                                <input checked={subjectType} className='inp_radio' type='radio' name='subjectType' value={'Premium'} onChange={() => setSubjectType(true)} /> Premium
                            </div>
                            <div className='input_group'>
                                <label><strong>Description</strong></label>
                                <textarea id='sub_desc' defaultValue={selSubject.description} rows={4} className='form-control' name='description' placeholder='Description'></textarea>
                            </div>
                            <hr />
                            <h6><strong>Index</strong></h6>
                            <div className='input_group'>
                                <p className='user_email'>Lower number equals high priority<br />1 will show first, 10 will show last<br /> 0 is default which will show last
                                </p>
                                <input id='sub_indx' className='form-control' type='text' name='subjectIndex' placeholder='Index' defaultValue={selSubject.indx} />
                            </div>
                            {selSubject.title !== '' && <button className='form-control mt-3 submit_btn' onClick={() => AddSubject()}>Edit Subject</button>}
                            {selSubject.title === '' && <button className='form-control mt-3 submit_btn' onClick={() => AddSubject()}>Add Subject</button>}
                        </div>



                    </div>


                </div>

            </div>

            <Suspense fallback={<div className='container'>
                <div className='spinner_container'>
                    <Audio height="80"
                        width="80"
                        radius="9"
                        color="green"
                        ariaLabel="loading"
                        wrapperStyle
                        wrapperClass />
                </div>

            </div>}>
                <SubjectList updatesub={SelectSubject} base={props.base} bucket={props.bucket} />
            </Suspense>

        </>
    );
};

export default Subject;