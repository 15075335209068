import React, { Suspense, lazy } from 'react';
import './forums.css'
import { Audio } from 'react-loader-spinner';

const ForumList = lazy(() => import('../../components/ForumsList'));

function Forum(props) {

    return (

        <>
            <div className='page_title'>
                <h1>Discussion Forum</h1>
                <p className='subtext'>Manage Discussion Forum</p>
            </div>

            <Suspense fallback={<div className='container'>
                <div className='spinner_container'>
                    <Audio height="80"
                        width="80"
                        radius="9"
                        color="green"
                        ariaLabel="loading"
                        wrapperStyle
                        wrapperClass />
                </div>

            </div>}>
                <ForumList base={props.base} bucket={props.bucket} />
            </Suspense>

        </>
    );
};

export default Forum;