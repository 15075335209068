import React, { useEffect, useState } from 'react';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencil, faTrash, faTimes, faEye } from '@fortawesome/free-solid-svg-icons';
import { Audio } from 'react-loader-spinner';
import axios from 'axios';
import './subject.css'
import TopNavWithBack from '../../components/TopNav/topnavwithback';
import { baseurl } from '../../urls';
import AddCardModal from '../../components/AddCard';
import AddNoteModal from '../../components/AddNote';
import AddQuizModal from '../../components/AddQuiz';
import { Document, Page, pdfjs } from 'react-pdf'
import { message } from 'antd';
function SingleChapter(props) {
    pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;
    var base = sessionStorage.getItem('base')
    var bucket = sessionStorage.getItem('bucket')
    var title = sessionStorage.getItem('title');
    const { chapter } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const cid = searchParams.get('cid');
    const sid = searchParams.get('id');
    var [isLoaded, setIsLoaded] = useState(false);
    var [notesList, setNotesList] = useState([]);
    var [fcList, setFcList] = useState([]);
    var [qList, setQList] = useState([]);
    var [showModal, setShowModal] = useState(false);
    var [excelfile, setExcelFile] = useState();
    var [flashCardExcelfile, setFlashCardExcelFile] = useState();
    var [showNoteModal, setShowNoteModal] = useState(false);
    var [showQuizModal, setShowQuizModal] = useState(false);
    var notesample = {
        id: '',
        image: '',
        imageUrl: '',
    };
    var cardsample = {
        id: '',
        frontImage: '',
        frontImageUrl: '',
        backImage: '',
        backImageUrl: '',
    }
    var quizsample = {
        id: '',
        question: '',
        answer: '',
        option1: '',
        option2: '',
        option3: '',
        option4: '',
        multiple: true,
        explanation: '',
    };
    var [note, setNote] = useState(notesample)
    var [card, setCard] = useState(cardsample)
    var [quiz, setQuiz] = useState(quizsample)
    function getChapters() {
        axios.get(baseurl + "getcards?base=" + base + "&bucket=" + bucket + "&id=" + searchParams.get('id') + "&cid=" + searchParams.get('cid'))
            .then(res => res.data)
            .then((res) => {
                setIsLoaded(true);
                setNotesList(res.notes);
                setFcList(res.flashCards);
                setQList(res.quiz);
            });
    }
    function editCard(e) {
        var fc = {
            id: e.currentTarget.dataset.id,
            fronttext: e.currentTarget.dataset.fronttext,
            backtext: e.currentTarget.dataset.backtext,
            frontImage: '',
            frontImageUrl: e.currentTarget.dataset.front,
            backImage: '',
            backImageUrl: e.currentTarget.dataset.back,
        }
        setCard(fc);
        setShowModal(true)
    }
    function editNote(e) {
        var fc = {
            id: e.currentTarget.dataset.id,
            image: '',
            imageUrl: e.currentTarget.dataset.image,
        }
        setNote(fc);
        setShowNoteModal(true)
    }

    function editQuiz(e) {
        var fc = {
            id: e.currentTarget.dataset.id,
            question: e.currentTarget.dataset.q,
            answer: e.currentTarget.dataset.a,
            option1: e.currentTarget.dataset.op1,
            option2: e.currentTarget.dataset.op2,
            option3: e.currentTarget.dataset.op3,
            option4: e.currentTarget.dataset.op4,
            multiple: (e.currentTarget.dataset.type == 1),
            explanation: e.currentTarget.dataset.exp,
        }
        setQuiz(fc);
        setShowQuizModal(true)
    }
    function deleteNote(e) {
        var docId = e.currentTarget.dataset.id;
        axios.post(baseurl + 'deleteNote?base=' + base + '&sid=' + sid + '&cid=' + cid + '&id=' + docId)
            .then(() => {
                window.location.reload();
            });
    }
    function deleteCard(e) {
        var docId = e.currentTarget.dataset.id;
        axios.post(baseurl + 'deleteCard?base=' + base + '&sid=' + sid + '&cid=' + cid + '&id=' + docId)
            .then(() => {
                window.location.reload();
            });
    }
    function deleteQuiz(e) {
        var docId = e.currentTarget.dataset.id;
        axios.post(baseurl + 'deleteQuiz?base=' + base + '&sid=' + sid + '&cid=' + cid + '&id=' + docId)
            .then(() => {
                window.location.reload();
            });
    }

    function CloseModal() {
        var overlay = document.getElementById('subjectmodal');
        var modal = document.getElementById('modal');
        overlay.classList.toggle('hide');
        modal.classList.toggle('hide');
    }
    function UploadExcel(e) {
        var file = e.target.files[0];
        setExcelFile(file);
    }
    function UploadFlashCardExcel(e) {
        var file = e.target.files[0];
        setFlashCardExcelFile(file);
    }
    function UploadQuiz() {
        var fm = new FormData();
        fm.append("cid", cid);
        fm.append("sid", sid);
        fm.append("quiz", excelfile);
        axios.post(baseurl + "addquizbulk?base=" + base, fm).then(() => {
            message.success('Quiz added successfully from excel!!')
            window.location.reload();
        })
    }
    function UploadFlashCardBulk() {
        var fm = new FormData();
        fm.append("cid", cid);
        fm.append("sid", sid);
        fm.append("card", flashCardExcelfile);
        axios.post(baseurl + "addcardbulk?base=" + base, fm).then(() => {
            message.success('Flashcards added successfully from excel!!')
            window.location.reload();
        })
    }
    useEffect(() => {
        getChapters();
    }, []);
    return (<>
        {chapter ? (<div className='main_page' style={{ height: "100vh" }}>
            <TopNavWithBack title={title} />
            {showModal && <AddCardModal card={card} setcard={setCard} togglemodal={setShowModal} cid={cid} sid={sid} />}
            {showNoteModal && <AddNoteModal note={note} setnote={setNote} togglemodal={setShowNoteModal} cid={cid} sid={sid} />}
            {showQuizModal && <AddQuizModal quiz={quiz} setquiz={setQuiz} togglemodal={setShowQuizModal} cid={cid} sid={sid} />}

            <div id="pageContent" className='mx-5 p-3 single_page_contents'>
                <div className='page_title'>
                    <h1>{chapter}</h1>
                    <p className='subtext'>Manage Notes,Flash Cards and Quiz of {chapter}</p>
                </div>

                <div className='head'>
                    {!isLoaded &&
                        <div className='spinner_container'>
                            <Audio height="80"
                                width="80"
                                radius="9"
                                color="green"
                                ariaLabel="loading"
                                wrapperStyle
                                wrapperClass />
                        </div>}
                    <div className='mb-3notes_section'>
                        <h1>Notes</h1>
                        {notesList.length == 0 && <div className='flash_card align-content-center text-center' style={{ background: '#eee' }}>
                            <p className="add_pointer" onClick={() => { setShowNoteModal(true); setNote(notesample); }}>+ Add Notes</p>
                        </div>
                        }
                        {isLoaded && notesList.map((sub) => {

                            return (<>

                                <div key={sub.pageCount} className='flash_card' style={{ backgroundImage: "url(" + sub.url + ")" }}>
                                    <Document file={sub.url}>
                                        <Page pageNumber={1} />
                                    </Document>
                                    <div className='options'>
                                        <a className='option' data-id={sub.id} data-front={sub.frontImage} data-back={sub.backImage} href={sub.url} target="_blank">
                                            <FontAwesomeIcon icon={faEye} />
                                            View
                                        </a>
                                        <p className='' data-id={cid} onClick={(e) => deleteNote(e)}>
                                            <FontAwesomeIcon icon={faTrash} /> Delete
                                        </p>
                                    </div>
                                </div>
                            </>)
                        })}
                    </div>
                    <div className='mb-3 notes_section'>
                        <h1>Flash Cards</h1>
                        <div className='flash_card align-content-center text-center' style={{ background: '#eee' }}>
                            <p className="add_pointer" onClick={() => { setShowModal(true); setCard(cardsample) }}>+ Add FlashCard</p>
                        </div>
                        <div className='flash_card align-content-center text-center' style={{ background: '#eee' }}>
                            {flashCardExcelfile && <div>
                                <p>{flashCardExcelfile.name}</p>
                                <button className='media_btn mx-auto' onClick={() => UploadFlashCardBulk()}>Upload</button></div>}
                            {!flashCardExcelfile && <label className="add_pointer">+ Add FlashCard from excel<input type='file' accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" onChange={(e) => UploadFlashCardExcel(e)} hidden /></label>}
                        </div>
                        {isLoaded && fcList.map((sub) => {
                            return (<>

                                <div key={sub.id} className='flash_card' style={{ backgroundImage: "url(" + sub.frontImage + ")" }}>
                                    {!sub.frontImage && <h5 className='p-3 text-light'>{sub.front}</h5>}
                                    <div className='options'>
                                        <p className='option' data-id={sub.id} data-fronttext={sub.front} data-backtext={sub.back} data-front={sub.frontImage} data-back={sub.backImage} onClick={(e) => editCard(e)}>
                                            <FontAwesomeIcon icon={faPencil} />
                                            Edit
                                        </p>
                                        <p className='option' data-id={sub.id} onClick={(e) => deleteCard(e)}>
                                            <FontAwesomeIcon icon={faTrash} />
                                            Delete
                                        </p>
                                    </div>
                                </div>
                            </>)
                        })}
                    </div>
                    <div className='notes_section'>
                        <h1>Quiz</h1>
                        <div className='flash_card align-content-center text-center' style={{ background: '#eee' }}>
                            <p className="add_pointer" onClick={() => { setShowQuizModal(true); setQuiz(quizsample) }}>+ Add Quiz</p>
                        </div>
                        <div className='flash_card align-content-center text-center' style={{ background: '#eee' }}>
                            {excelfile && <div>
                                <p>{excelfile.name}</p>
                                <button className='media_btn mx-auto' onClick={() => UploadQuiz()}>Upload</button></div>}
                            {!excelfile && <label className="add_pointer">+ Add Quiz from excel<input type='file' accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" onChange={(e) => UploadExcel(e)} hidden /></label>}
                        </div>
                        {isLoaded && qList.map((sub) => {
                            return (<>

                                <div key={sub.id} className='flash_card' style={{ backgroundImage: "url(" + sub.poster + ")" }}>
                                    <h5 className='p-3 text-light'>{sub.question}</h5>
                                    <div className='options'>
                                        <p className='option' data-id={sub.id}
                                            data-q={sub.question}
                                            data-a={sub.correctAns}
                                            data-type={sub.quizType}
                                            data-op1={sub.options[0]}
                                            data-op2={sub.options[1]}
                                            data-op3={sub.options[2]}
                                            data-op4={sub.options[3]}
                                            data-exp={sub.description} onClick={(e) => editQuiz(e)}>
                                            <FontAwesomeIcon icon={faPencil} />
                                            Edit
                                        </p>
                                        <p className='option' data-id={sub.id} onClick={(e) => deleteQuiz(e)}>
                                            <FontAwesomeIcon icon={faTrash} />
                                            Delete
                                        </p>
                                    </div>
                                </div>
                            </>)
                        })}
                    </div>

                </div>
            </div>
        </div>) : (
            <div lassName='main_page' style={{ height: "100vh" }}>
                <h1> No Subject</h1>
            </div>)
        }
    </>);

}
export default SingleChapter;