import React, { useEffect, useState } from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'

import Subject from './Subjects/subject'
import Settings from './Settings/setting'
import Users from './Users/users'
import Forum from './DiscussionForums/forum'
import SideNav from '../components/SideNav/sidenavbar'
import Home from './Dashboard/home'
import TopNav from '../components/TopNav/topnav'

const MainPage = () => {
    var title = sessionStorage.getItem('title');
    var base = sessionStorage.getItem('base');
    var bucket = sessionStorage.getItem('bucket');
    const [navActive, setNavActive] = useState(1);
    const location = useLocation();

    useEffect(() => {
        signinCheck();
        switch (location.pathname) {
            default:
                setNavActive(1);
                break;
            case '/login':
                setNavActive(2);
                break;
            case '/subject':
                setNavActive(3);
                break;
            case '/users':
                setNavActive(4);
                break;
            case '/discussion':
                setNavActive(5);
                break;
            case '/settings':
                setNavActive(6);
                break;
        }
    }, [location.pathname]);

    const signinCheck = () => {
        if (!window.sessionStorage.getItem('userName')) {
            window.location.replace('/login');
        }
    }

    return (
        <div className='main_page' style={{ height: "100vh" }}>
            <TopNav title={title} />
            <div id="sidenav" className='side_nav_section'>
                <SideNav navActive={navActive} setNavActive={setNavActive} />
            </div>
            <div id="pageContent" className='page_contents'>
                <Routes>
                    <Route path='/' element={<Home base={base} bucket={bucket} />} />
                    <Route path='/subject' element={<Subject base={base} bucket={bucket} />} />
                    <Route path='/settings' element={<Settings base={base} bucket={bucket} />} />
                    <Route path='/users' element={<Users base={base} bucket={bucket} />} />
                    <Route path='/discussion' element={<Forum base={base} bucket={bucket} />} />

                </Routes>
            </div>
        </div>
    )
}

export default MainPage