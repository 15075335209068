import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHandHoldingHeart, faUsers, faFileText, faThLarge, faCog, faSignOutAlt, faCaretLeft } from '@fortawesome/free-solid-svg-icons'
import './sidenav.css';
import { Link, useNavigate } from 'react-router-dom';

function SideNav({ navActive, setNavActive }) {
    const navigate = useNavigate();
    const navItems = [
        {
            id: 0,
            name: "Dashboard",
            path: '/',
            icon: <FontAwesomeIcon icon={faThLarge} />,
        },
        {
            id: 1,
            name: "Subject",
            path: '/subject',
            icon: <FontAwesomeIcon icon={faFileText} />,
        },
        {
            id: 2,
            name: "Users",
            path: '/users',
            icon: <FontAwesomeIcon icon={faUsers} />,
        },
        {
            id: 3,
            name: "Discussion Forum",
            path: '/discussion',
            icon: <FontAwesomeIcon icon={faHandHoldingHeart} />,
        },
        {
            id: 4,
            name: "Settings",
            path: '/settings',
            icon: <FontAwesomeIcon icon={faCog} />,
        },
        {
            id: 5,
            name: "Logout",
            path: '/login',
            icon: <FontAwesomeIcon icon={faSignOutAlt} />,
        },
        {
            id: 6,
            name: "Collapse",
            path: '#',
            icon: <FontAwesomeIcon icon={faCaretLeft} />,
        }];
    const navStyle = {
        active: "active",
        inActive: "inactive"
    };
    const handleLogout = () => {
        sessionStorage.removeItem('userData');
        navigate('home');
    };

    const handleOnClickLink = (id) => {
        if (id === 6) {
            document.getElementById('sidenav').classList.toggle('shrink');
            document.getElementById('pageContent').classList.toggle('expand');
        } else {
            setNavActive(id);
        }
    };
    return (
        <>
            <div className='container'>
                <ul className='nav_menu_list'>

                    {navItems.map((navItem) => {
                        if (navItem.id <= 3) {
                            return (
                                <li>
                                    <Link to={navItem.path} key={navItem.id} className={"navLink " + navItem.id === navActive ? navStyle.active : navStyle.inActive} onClick={() => handleOnClickLink(navItem.id)}>
                                        <p className='shrinked'>
                                            {navItem.icon}
                                            {navItem.name}
                                        </p>
                                    </Link>
                                </li>
                            );
                        }
                    }
                    )}

                </ul>

                <ul className='nav_menu_list setting_list'>
                    {navItems.map((navItem) => {
                        if (navItem.id > 3) {
                            return (<li><Link to={navItem.path} key={navItem.id} className={navItem.id === navActive ? navStyle.active : navStyle.inActive} onClick={() => handleOnClickLink(navItem.id)}>

                                <p className='shrinked'>
                                    {navItem.icon}
                                    {navItem.name}
                                </p>
                            </Link>
                            </li>
                            );
                        }
                    }
                    )}
                </ul>
            </div>
        </>
    );
};
export default SideNav;