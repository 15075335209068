import react, { useEffect, useState } from 'react';
import React, { Suspense, lazy } from 'react';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { Audio } from 'react-loader-spinner';
import axios from 'axios';
import { baseurl } from '../../urls';
import './users.css'
import defaultUser from '../../assets/defaultUserColor.png';
import { Switch } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

function Users(props) {
    var app = sessionStorage.getItem('app');
    var base = sessionStorage.getItem('base');
    var [user, setUser] = useState();
    var [isLoaded, setIsLoaded] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const uid = searchParams.get('uid');

    function GetUsers() {
        if (!window.sessionStorage.getItem('userName')) {
            window.location.replace('/login');
        } else {
            axios.get(baseurl + "userDetails?base=" + base + "&uid=" + uid)
                .then(res => res.data)
                .then((res) => {
                    setIsLoaded(true);
                    setUser(res);
                });
        }
    }
    const toggleSubscription = (e) => {
        var id = e.currentTarget.parentElement.parentElement.dataset.id;
        var subscribe = e.currentTarget.parentElement.parentElement.dataset.subs;
        axios.post(baseurl + 'updateuser?base=' + base + '&id=' + id + '&value=' + subscribe)
            .then(() => {
                window.location.reload();
            })
    }
    useEffect(GetUsers, []);

    return (
        <>
            <div className='page_title'>
                <h1>User Details</h1>
            </div>

            {!isLoaded &&
                <div className='container'>
                    <div className='spinner_container'>
                        <Audio height="80"
                            width="80"
                            radius="9"
                            color="green"
                            ariaLabel="loading"
                            wrapperStyle
                            wrapperClass />
                    </div>

                </div>

            }
            {isLoaded &&
                <div className=''>

                    <div className='col-md-6 d-inline-block my-3 '>
                        <div className='user_card'>
                            <div className='user_section d-flex'>
                                <div className='user_image2 mr-3'>
                                    <img src={user.profilepic ? user.profilepic : defaultUser} alt="user"/>
                                </div>
                                <div className='user_details'>
                                    <div style={{display: 'flex', alignItems: 'baseline'}}>
                                        <h4>{user.firstName + " " + user.lastName}</h4>                                        
                                        {(user.isSubscribe || user.isSubscribeByAdmin) && <FontAwesomeIcon icon={faCheckCircle} style={{ color: 'green', marginLeft: '5px' }} />}
                                    </div>
                                    <p className='user_email mb-0'>
                                        {user.email}
                                    </p>
                                    <div className='d-flex'>
                                        <p className='user_email mr-3'>Followers: <span className='text-dark'>{user.followers}</span></p>
                                        <p className='user_email'>Following: <span className='text-dark'>{user.following}</span></p>
                                    </div>

                                </div>
                            </div>
                            <hr />
                            <div className='subscription'>
                                <p><strong>Signup:</strong> {user.createdDate.split('GMT')[0]}   </p>
                                <p><strong>Last Login Time:</strong> {user.lastLoginTime.split('GMT')[0]}   </p>
                                <label> <b>Subscription Status</b>{(user.isSubscribe || user.isSubscribeByAdmin) && <Switch data-id={user.userId} data-subs={(user.isSubscribe || user.isSubscribeByAdmin)} defaultChecked onChange={(e) => toggleSubscription(e)} color='primary' />}
                                    {!(user.isSubscribe || user.isSubscribeByAdmin) && <Switch data-id={user.userId} data-subs={(user.isSubscribe || user.isSubscribeByAdmin)} color='primary' onChange={(e) => toggleSubscription(e)} />}</label>
                            </div>
                        </div>
                    </div>

                </div>
            }

        </>
    );
};


export default Users;