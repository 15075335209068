import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import MainPage from './pages/mainPage';
import Login from './pages/Login/login';
import SingleSubject from './pages/Subjects/subjectSingle';
import SingleChapter from './pages/Subjects/singleChapter';
import Appslist from './pages/Login/Appslist';
import UserDetails from './pages/Users/userDetails.jsx';                                      


function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path='/login' element={<Login />} />
          <Route path='/appList' element={<Appslist />} />
          <Route path='/userDetails' element={<UserDetails />} />
          <Route path='subject/:name' element={<SingleSubject />} />
          <Route path='subject/:name/:chapter' element={<SingleChapter />} />
          <Route path='*' element={<MainPage />} />

        </Routes>
      </BrowserRouter>
    </>

  );
}

export default App;
