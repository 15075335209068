import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import AdminNav from '../../components/TopNav/adminNav';
import applogo from '../../assets/NFlogo.png'
import medlogo from '../../assets/MedLogo.png'
import vetlogo from '../../assets/VetLogo.png'
import pmplogo from '../../assets/PMPLogo.png'
import reallogo from '../../assets/RealLogo.png'
import grelogo from '../../assets/GRELogo.png'
import toefllogo from '../../assets/TOEFLlogo.png'
import ieltslogo from '../../assets/IELTSLogo.png'
import asvablogo from '../../assets/ASVABLogo.png'
import cnalogo from '../../assets/CNALogo.png'
import dentallogo from '../../assets/DentalLogo.png'
import satlogo from '../../assets/SatLogo.png'
import nclexlogo from '../../assets/NCLEX-PN-Logo.png'
import atiteaslogo from '../../assets/ATITEAS-Logo.png'

const Appslist = () => {
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    function SigninCheck() {
        var s = sessionStorage.getItem('isLoggedin');
        if (s != null && s) {
            setUsername(sessionStorage.getItem('userName'))
        } else {
            navigate('/login');
        }
    }
    useEffect(SigninCheck, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        // try {
        //     var response = await loginform(formData);
        //     if (response && response.success) {
        //         message.success(response.message);
        //         sessionStorage.setItem('userData', JSON.stringify(response.data)); // Use sessionStorage instead of localStorage
        //         navigate('/');
        //     } else {
        //         message.error(response ? response.message : 'Unknown error');
        //     }

        // } catch (error) {
        //     console.error('Error during login:', error);
        //     message.error('Error during login. Please try again later.');
        // }
    };
    function GoToApp(e) {

        var id = e.currentTarget.dataset.id;
        var title = e.currentTarget.dataset.title;
        var base = e.currentTarget.dataset.base;
        var bucket = e.currentTarget.dataset.bucket;

        sessionStorage.setItem('title', title);
        sessionStorage.setItem('id', id);
        sessionStorage.setItem('base', base);
        sessionStorage.setItem('bucket', bucket);
        navigate('/');
    }
    const AppList = [
        {
            title: 'Nursing Focus',
            id: 0,
            image: applogo,
            base: 'firebase',
            bucket: 'firebucket'
        },
        {
            title: 'Pmp Focus',
            id: 1,
            image: pmplogo,
            base: 'pmpbase',
            bucket: 'pmpbucket'
        },
        {
            title: 'Med Focus',
            id: 2,
            image: medlogo,
            base: 'medbase',
            bucket: 'medbucket'
        },
        {
            title: 'RealEstate Focus',
            id: 3,
            image: reallogo,
            base: 'realbase',
            bucket: 'realbucket'
        },
        {
            title: 'Vet Focus',
            id: 4,
            image: vetlogo,
            base: 'vetbase',
            bucket: 'vetbucket'
        },
        {
            title: 'GRE Focus',
            id: 5,
            image: grelogo,
            base: 'grebase',
            bucket: 'grebucket'
        },
        {
            title: 'TOEFL Focus',
            id: 6,
            image: toefllogo,
            base: 'toeflbase',
            bucket: 'toeflbucket'
        },
        {
            title: 'IELTS Focus',
            id: 7,
            image: ieltslogo,
            base: 'ieltsbase',
            bucket: 'ieltsbucket'
        },
        {
            title: 'Dental Focus',
            id: 7,
            image: dentallogo,
            base: 'dentalbase',
            bucket: 'dentalbucket'
        },
        {
            title: 'CNA Focus',
            id: 7,
            image: cnalogo,
            base: 'cnabase',
            bucket: 'cnabucket'
        },
        {
            title: 'SAT Focus',
            id: 7,
            image: satlogo,
            base: 'satbase',
            bucket: 'satbucket'
        },
        {
            title: 'ASVAB Focus',
            id: 7,
            image: asvablogo,
            base: 'asvabbase',
            bucket: 'asvabbucket'
        },
        {
            title: 'Nursing Focus ATITEAS',
            id: 8,
            image: atiteaslogo,
            base: 'atiteasbase',
            bucket: 'atiteasbucket'
        },
        {
            title: 'Nursing Focus NCLEX-PN',
            id: 9,
            image: nclexlogo,
            base: 'nclexpnbase',
            bucket: 'nclexpnbucket'
        }]


    return (
        <>
            <AdminNav username={username} />
            <div className='container my-5'>
                <div className='page_title'>
                    <h1>Focus Apps</h1>
                    <p className='subtext'>Choose one App to continue</p>
                </div>
                <div className='my-3'>
                    <div className='app_cards'>
                        {AppList.map((app) => {
                            return <div key={app.id} data-title={app.title} data-id={app.id} data-base={app.base} data-bucket={app.bucket} onClick={(e) => GoToApp(e)} className='col-md-2 app_card'>
                                <img src={app.image} alt="No Image" />
                                <h5>{app.title}</h5>
                            </div>;
                        })}

                    </div>
                </div>
            </div>

        </>
    );
};
export default Appslist;