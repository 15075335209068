import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { message } from 'antd';
import axios from 'axios'
import { baseurl } from '../../urls';

const Login = () => {
    sessionStorage.clear();
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        userName: '',
        password: '',
    });

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            var response;
            axios.get(baseurl + 'adminlogin?username=' + formData.userName + '&password=' + formData.password)
                .then((res) => {
                    response = res;
                }).then(() => {
                    if (response) {
                        message.success("Login Successfull!")
                        sessionStorage.setItem('userName', formData.userName);
                        sessionStorage.setItem('isLoggedin', true);
                        navigate('/appList');
                    } else {
                        sessionStorage.setItem('isLoggedin', false);
                        message.error(response ? 'Unknown error' : 'Invalid Credentials');
                    }
                }
                );


        } catch (error) {
            console.error('Error during login:', error);
            message.error('Error during login. Please try again later.');
        }
    };

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    return (
        <>
            <div className='login_main'>
                <div className='container align-content-center text-center login'>
                    <div className='col-md-5 m-auto login_card'>
                        <h2>Focus Admin Login</h2>
                        <div className="loginComponent">
                            <div className='login'>
                                <form onSubmit={handleSubmit}>
                                    <div className='inputsGroup'>
                                        <div className='form-group'>
                                            <input
                                                className='form-control'
                                                type='text'
                                                id='userName'
                                                name='userName'
                                                value={formData.userName}
                                                onChange={handleChange}
                                                required
                                                placeholder='User Name*'
                                            />
                                        </div>
                                        <div>
                                            <div className='form-group' style={{ marginBottom: "0.5rem" }}>
                                                <input
                                                    className='form-control'
                                                    type='password'
                                                    id='password'
                                                    name='password'
                                                    value={formData.password}
                                                    onChange={handleChange}
                                                    required
                                                    placeholder='Password*'
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <button className='btn btn-light' type='submit'>Login</button>
                                </form>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
};
export default Login;