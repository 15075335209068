import React from 'react'
import './topnav.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom';

function TopNavWithBack(props) {
    let navigate = useNavigate();
    return (
        <>
            <div className='container_fluid top_nav'>
                <div className='brandback'>
                    <a onClick={() => navigate(-1)}><FontAwesomeIcon icon={faArrowLeft} /></a>
                    <h4>{props.title} Admin</h4>
                </div>

            </div>
        </>
    );
}
export default TopNavWithBack;