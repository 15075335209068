import React, { useEffect, useState } from 'react';
import './home.css'
import axios from 'axios'
import { Audio } from 'react-loader-spinner';
import { baseurl } from '../../urls';
import { Link } from 'react-router-dom';

function Home(props) {
    const [users, setUsers] = useState([]);
    const [discussions, setDiscussions] = useState([]);
    const [totalusers, setTotalUsers] = useState(0);
    const [activeusers, setActivelUsers] = useState(0);
    const [prmusers, setPremiumUsers] = useState(0);
    const [totalDiscs, setTotalDiscs] = useState(0);
    const [repDiscs, setRepDiscs] = useState(0);
    const [isLoaded, setIsLoaded] = useState(false);

    //var [iscalled, setIsCalled] = useState(false);
    function getData() {
        axios.get(baseurl + "dashboard?base=" + props.base + "&bucket=" + props.bucket)
            .then(res => res.data)
            .then((res) => {
                setActivelUsers(res.activeusers.count);
                setTotalUsers(res.totalusers.count);
                setPremiumUsers(res.premiumusers.count);
                setTotalDiscs(res.totaldisc.count);
                setRepDiscs(res.repdisc.count)
                setDiscussions(res.discussions);
                setUsers(res.users);
                setIsLoaded(true);
            })
    }
    useEffect(() => {
        if (!window.sessionStorage.getItem('userName')) {
            window.location.replace('/login');
        } else {
            getData();
        }

    }, []);

    return (
        <>
            <div className='page_title'>
                <h1>Dashboard</h1>
                <p className='subtext'>Admin Statistics Data</p>
            </div>
            {!isLoaded &&
                <div className='container'>
                    <div className='spinner_container'>
                        <Audio height="80"
                            width="80"
                            radius="9"
                            color="green"
                            ariaLabel="loading"
                            wrapperStyle
                            wrapperClass />
                    </div>

                </div>
            }
            {isLoaded && <div className='page_ct'>
                <div className='stats'>
                    <div className='stat_card'>
                        <h2>Users</h2>
                        <p className='subtext'>Active Users / Total Users</p>
                        <b><span>{activeusers}</span>/{totalusers}</b>
                    </div>
                    <div className='stat_card'>
                        <h2>Users</h2>
                        <p className='subtext'>Subscribed / Total Users</p>
                        <b><span>{prmusers}</span>/{totalusers}</b>
                    </div>
                    <div className='stat_card'>
                        <h2>Discussion Forum</h2>
                        <p className='subtext'>Reported Forums / Total Forums</p>
                        <b><span>{repDiscs}</span>/{totalDiscs}</b>
                    </div>
                </div>
                <div className='tables mt-4'>
                    <div className='tbl_users container'>
                        <h3 className='view_all_action'>View All</h3>
                        <h2>Users</h2>
                        <p className='subtext'>Recently Joined</p>
                        <div className='table_mdrn'>
                            <table className='table'>
                                <thead>
                                    <tr>
                                        <th>Username</th>
                                        <th>Created Date</th>
                                        <th>Status</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {users.map((user) => {
                                        return (
                                            <>
                                                <tr>
                                                    <td>{user.firstName}</td>
                                                    <td>{user.createdDate}</td>
                                                    <td></td>
                                                    <td>
                                                    <Link to={`userDetails?base=${props.base}&uid=${user.userId}`}>
                                                    <button className="action_btn"> View</button>
                                                    </Link></td>
                                                </tr>
                                            </>
                                        );
                                    })}
                                </tbody>
                            </table>

                        </div>
                    </div>
                    <div className='tbl_forums container'>
                        <h3 className='view_all_action'>View All</h3>
                        <h2>Discussion Forum</h2>
                        <p className='subtext'>Recently Reported</p>
                        <div className='table_mdrn'>
                            <table className='table'>
                                <thead>
                                    <tr>
                                        <th>Username</th>
                                        <th>Created Date</th>
                                        <th>Status</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {discussions.map((discussion) => {
                                        return (
                                            <>
                                                <tr>
                                                    <td>{discussion.forumName}</td>
                                                    <td>{discussion.createdDate}</td>
                                                    <td></td>
                                                    <td><button className="action_btn"> View</button></td>
                                                </tr>
                                            </>
                                        );
                                    })}
                                </tbody>
                            </table>

                        </div>
                        <table>

                        </table>
                    </div>
                </div>
            </div>
            }

        </>
    );
};

export default Home;